import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
  subject as subjectHelper,
} from '@casl/ability';
import { useCallback, useMemo } from 'react';

import { Permission } from '../../../types/graphql.generated';
import { GenericCan, GenericCanSharedProps } from '../components/GenericCan';
import { CurrentUserAbilityFragment, TaskAbilityFragment } from '../graphql';
import { detectSubjectTypeByTypename as detectSubjectType } from '../helpers';
import { useMeOrDefault } from '../hooks';

interface TaskSubject {
  me: CurrentUserAbilityFragment;
  task?: TaskAbilityFragment;
}

type TaskSubjectArg = Partial<TaskSubject>;

type Actions =
  // global task actions
  | 'viewTasks'
  | 'filterTasks'
  | 'filterTasksByAdjuster'
  | 'filterTasksByLeader'
  | 'viewTask'
  | 'createTask'
  | 'editDrivingLog'
  | 'viewDrivingLog'
  | 'updateFollowUpDate'
  | 'updateReminderDate'
  // batch actions
  | 'batchAssign'
  | 'batchUpdateClassification'
  | 'batchDeleteClassification'
  // edit common sections
  | 'editBaseData'
  | 'addContact'
  | 'editContact'
  | 'addLocation'
  | 'editLocation'
  | 'editTemplate'
  | 'editOwner'
  | 'editLeader'
  | 'editDamage'
  // task specific actions
  | 'activateTask'
  | 'acceptTask'
  | 'cancelTask'
  | 'revertCancelTask'
  | 'completeTask'
  | 'declineTask'
  | 'editTask'
  | 'duplicateTask'
  | 'setInitialContact'
  | 'setFirstContact'
  | 'updateTaskQuestions'
  | 'updateTaskForm'
  | 'deleteTaskForm'
  | 'updateTaskFormQuestionComments'
  | 'updateTaskFormQuestionForcePageBreak'
  | 'createTaskLog'
  | 'startDocumentation'
  | 'resetAssignment'
  // task controlling actions
  | 'controllingTask'
  | 'revertControllingTask'
  | 'sendReport'
  | 'updateReports'
  | 'reworkTask'
  | 'reworkTaskDone'
  // task appointment actions
  | 'setAppointment'
  | 'resetAppointment'
  // classification tags
  | 'updateClassification'
  | 'deleteClassification'
  // task files actions
  | 'updateTaskFolderPermissions'
  // task qualifications
  | 'updateQualifications'
  // task tab visibility
  | 'viewTaskAppointments'
  | 'viewTaskCalculation'
  | 'updateTaskCalculation'
  | 'viewTaskCommon'
  | 'viewTaskCustomerPortal'
  | 'viewTaskDocuments'
  | 'uploadTaskDocuments'
  | 'viewTaskReworkDocuments'
  | 'updateTaskReworkDocuments'
  | 'viewTaskEmails'
  | 'updateTaskEmails'
  | 'sendDirectMessageFromEmailTemplate'
  | 'sendDirectMessageFromInterfaceMessageTemplate'
  | 'viewTaskFiles'
  | 'uploadTaskFiles'
  | 'viewTaskForm'
  | 'viewTaskFormAdditional'
  | 'viewTaskImages'
  | 'uploadTaskImages'
  | 'viewTaskInvoices'
  | 'viewTaskProposals'
  | 'viewTaskLogs'
  | 'viewTaskReport'
  | 'viewTaskRoomItems'
  | 'viewTaskStatistics'
  | 'viewTaskTodos'
  | 'viewDamageSection'
  | 'updateDamageSection'
  | 'exportTasks'
  | 'exportTaskStatistics'
  | 'exportReworkDocuments'
  | 'sendSms'
  | 'updateStatisticSlots';

type Subjects = TaskSubject | 'TaskSubject';

export enum TaskActionType {
  ACCEPT = 'acceptTask',
  ACTIVATE = 'activateTask',
  START_DOCUMENTATION = 'startDocumentation',
  ASSIGN_OWNER = 'editOwner',
  RESET_ASSIGNMENT = 'resetAssignment',
  CANCEL = 'cancelTask',
  REVERT_CANCEL = 'revertCancelTask',
  COMPLETE = 'completeTask',
  DECLINE = 'declineTask',
  EDIT = 'editTask',
  DUPLICATE = 'duplicateTask',
  VIEW = 'viewTask',
  SET_APPOINTMENT = 'setAppointment',
  RESET_APPOINTMENT = 'resetAppointment',
  CONTROLLING = 'controllingTask',
  REVERT_CONTROLLING = 'revertControllingTask',
  UPDATE_CLASSIFICATION = 'updateClassification',
  DELETE_CLASSIFICATION = 'deleteClassification',
  SET_INITIAL_CONTACT = 'setInitialContact',
  TASK_SET_FIRST_CONTACT = 'setFirstContact',
  SEND_REPORT = 'sendReport',
  TASK_UPDATE_REPORTS = 'updateReports',
  UPDATE_DRIVE_LOG = 'editDrivingLog',
  READ_DRIVE_LOG = 'viewDrivingLog',
  UPDATE_FOLLOW_UP_DATE = 'updateFollowUpDate',
  UPDATE_REMINDER_DATE = 'updateReminderDate',
  UPDATE_QUALIFICATIONS = 'updateQualifications',
  REWORK = 'reworkTask',
  REWORK_DONE = 'reworkTaskDone',
  SEND_DIRECT_MESSAGE_FROM_EMAIL_TEMPLATE = 'sendDirectMessageFromEmailTemplate',
  SEND_DIRECT_MESSAGE_FROM_INTERFACE_MESSAGE_TEMPLATE = 'sendDirectMessageFromInterfaceMessageTemplate',

  //batch actions
  TASK_BATCH_ASSIGN = 'batchAssign',
  BATCH_UPDATE_CLASSIFICATION = 'batchUpdateClassification',
  BATCH_DELETE_CLASSIFICATION = 'batchDeleteClassification',
  SEND_SMS = 'sendSms',
}

type TaskAbility = Ability<[Actions, Subjects]>;
const taskAbility = Ability as AbilityClass<TaskAbility>;

export const useTaskAbility = (): [
  TaskAbility,
  (sub?: TaskSubjectArg) => TaskSubject & ForcedSubject<'TaskSubject'>,
] => {
  const ability = useMemo(() => {
    const { can, build } = new AbilityBuilder(taskAbility);

    // global task actions
    can('viewTasks', 'TaskSubject', { 'me.globalPermissions': { $in: [Permission.TASK_INDEX] } });
    can('filterTasks', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_FILTER] },
    });
    can('filterTasksByAdjuster', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_FILTER_ADJUSTERS] },
    });
    can('filterTasksByLeader', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_FILTER_LEADERS] },
    });
    can('viewTask', 'TaskSubject', { 'task.permissions': { $in: [Permission.TASK_READ] } });
    can('createTask', 'TaskSubject', { 'me.globalPermissions': { $in: [Permission.TASK_CREATE] } });

    // batch actions
    can('batchAssign', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_BATCH_ASSIGN] },
    });
    can('batchUpdateClassification', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_BATCH_UPDATE_CLASSIFICATION] },
    });
    can('batchDeleteClassification', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_BATCH_DELETE_CLASSIFICATION] },
    });

    can('exportTasks', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_EXPORT] },
    });
    can('exportTaskStatistics', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_EXPORT_STATISTICS] },
    });
    can('exportReworkDocuments', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_EXPORT_REWORK_DOCUMENTS] },
    });

    // edit common sections

    can('editBaseData', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_UPDATE] },
    });
    can('addContact', 'TaskSubject', { 'me.globalPermissions': { $in: [Permission.TASK_UPDATE] } });
    can('editContact', 'TaskSubject', {
      'me.globalPermissions': { $in: [Permission.TASK_UPDATE] },
    });
    can('editLocation', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_LOCATION] },
    });
    can('addLocation', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_CREATE_LOCATION] },
    });

    can('editTemplate', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_CHANGE_FORM_TEMPLATE] },
    });
    can('editOwner', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_ASSIGN_OWNER] },
    });
    can('resetAssignment', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_RESET_ASSIGNMENT] },
    });
    can('editLeader', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_ASSIGN_LEADER] },
    });
    can('editDamage', 'TaskSubject', { 'me.globalPermissions': { $in: [Permission.TASK_UPDATE] } });
    can('editDrivingLog', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_DRIVE_LOG] },
    });
    can('viewDrivingLog', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_DRIVE_LOG] },
    });
    can('updateFollowUpDate', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_FOLLOW_UP_DATE] },
    });
    can('updateReminderDate', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_REMINDER_DATE] },
    });

    // task controlling actions
    can('startDocumentation', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_START_DOCUMENTATION] },
    });
    can('acceptTask', 'TaskSubject', { 'task.permissions': { $in: [Permission.TASK_ACCEPT] } });
    can('activateTask', 'TaskSubject', { 'task.permissions': { $in: [Permission.TASK_ACTIVATE] } });
    can('cancelTask', 'TaskSubject', { 'task.permissions': { $in: [Permission.TASK_CANCEL] } });
    can('revertCancelTask', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_REVERT_CANCEL] },
    });
    can('completeTask', 'TaskSubject', { 'task.permissions': { $in: [Permission.TASK_COMPLETE] } });
    can('declineTask', 'TaskSubject', { 'task.permissions': { $in: [Permission.TASK_DECLINE] } });
    can('editTask', 'TaskSubject', { 'task.permissions': { $in: [Permission.TASK_EDIT] } });
    can('duplicateTask', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_DUPLICATE] },
    });
    can('setInitialContact', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_SET_INITIAL_CONTACT] },
    });
    can('setFirstContact', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_SET_FIRST_CONTACT] },
    });
    can('updateTaskQuestions', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_QUESTIONS] },
    });
    can('updateTaskForm', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_FORM] },
    });
    can('deleteTaskForm', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_DELETE_FORM] },
    });
    can('updateTaskFormQuestionComments', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_FORM_QUESTION_COMMENTS] },
    });
    can('updateTaskFormQuestionForcePageBreak', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_FORM_QUESTION_FORCE_PAGE_BREAK] },
    });
    can('createTaskLog', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_LOG] },
    });
    can('reworkTask', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_REWORK] },
    });
    can('reworkTaskDone', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_REWORK_DONE] },
    });

    // task controlling actions
    can('controllingTask', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_CONTROLLING] },
    });
    can('revertControllingTask', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_REVERT_CONTROLLING] },
    });
    can('sendReport', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_SET_REPORT_SENT] },
    });
    can('updateReports', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_REPORTS] },
    });

    can('viewDamageSection', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_DAMAGE_SECTION] },
    });
    can('updateDamageSection', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_DAMAGE_SECTION] },
    });

    // task qualifications
    can('updateQualifications', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_QUALIFICATIONS] },
    });

    // task appointment actions
    can('setAppointment', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_SET_APPOINTMENT] },
    });
    can('resetAppointment', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_RESET_APPOINTMENT] },
    });

    // classification
    can('updateClassification', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_CLASSIFICATION] },
    });
    can('deleteClassification', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_DELETE_CLASSIFICATION] },
    });

    // task files actions
    can('updateTaskFolderPermissions', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_FOLDER_CREATE_FOLDER] },
    });

    // task tab visibility

    can('viewTaskAppointments', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_CALENDAR] },
    });
    can('viewTaskCommon', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_GLOBAL] },
    });
    can('viewTaskForm', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_FORM] },
    });
    can('viewTaskFormAdditional', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_FORM_EXTENDED] },
    });
    can('viewTaskReport', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_REPORTS] },
    });
    can('viewTaskTodos', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_SUBTASKS] },
    });

    can('viewTaskCalculation', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_CALCULATION] },
    });
    can('updateTaskCalculation', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_CALCULATION] },
    });
    can('viewTaskCustomerPortal', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_CUSTOMER_PORTAL] },
    });
    can('viewTaskDocuments', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_DOCUMENTS] },
    });
    can('uploadTaskDocuments', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_DOCUMENTS] },
    });
    can('viewTaskReworkDocuments', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_REWORK_DOCUMENTS] },
    });
    can('updateTaskReworkDocuments', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_REWORK_DOCUMENTS] },
    });
    can('viewTaskEmails', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_EMAILS] },
    });
    can('updateTaskEmails', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_EMAILS] },
    });
    can('sendDirectMessageFromEmailTemplate', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_SEND_DIRECT_MESSAGE_FROM_EMAIL_TEMPLATE] },
    });
    can('sendDirectMessageFromInterfaceMessageTemplate', 'TaskSubject', {
      'task.permissions': {
        $in: [Permission.TASK_SEND_DIRECT_MESSAGE_FROM_INTERFACE_MESSAGE_TEMPLATE],
      },
    });
    can('viewTaskFiles', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_FILES] },
    });
    can('uploadTaskFiles', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_FILES] },
    });
    can('viewTaskImages', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_IMAGES] },
    });
    can('uploadTaskImages', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_IMAGES] },
    });
    can('viewTaskInvoices', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_INVOICES] },
    });
    can('viewTaskProposals', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_PROPOSALS] },
    });
    can('viewTaskLogs', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_LOG] },
    });
    can('viewTaskRoomItems', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_ROOM_PLAN] },
    });
    can('viewTaskStatistics', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_READ_STATISTICS] },
    });
    can('sendSms', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_SEND_SMS_MESSAGE] },
    });
    can('updateStatisticSlots', 'TaskSubject', {
      'task.permissions': { $in: [Permission.TASK_UPDATE_STATISTICS_SLOTS] },
    });

    return build({ detectSubjectType });
  }, []);

  const me = useMeOrDefault();
  const subject = useCallback(
    (sub?: TaskSubjectArg) => {
      return subjectHelper('TaskSubject', { me, ...sub });
    },
    [me],
  );

  return [ability, subject];
};

interface CanTaskProps extends GenericCanSharedProps<Actions> {
  task?: TaskAbilityFragment;
}

export const CanTask = (props: CanTaskProps) => {
  const [taskAbility, taskSubject] = useTaskAbility();
  const { task, ...restProps } = props;

  return (
    <GenericCan<Actions, Subjects, TaskAbility>
      ability={taskAbility}
      subject={taskSubject({ task })}
      {...restProps}
    />
  );
};
