export default {
  labels: {
    coverTitle: 'Cover-Titel',
    tintColor: 'Tint Farbe',
    bgDarkColor: 'Hintergrundfarbe (dunkel)',
    highlightColor: 'Highlightfarbe',
    filename: 'Datei Name',
    footer: 'Fußzeile',
    footerPaginateTitle: 'Seitenbezeichner',
    question_filter: 'Fragen anzeigen',
    cover_layout: 'Cover-Layout',
    summary_layout: 'Zusammenfassungs-Layout',
    questions_layout: 'Fragen-Layout',
    roomplan_layout: 'Raum Plan-Layout',
    images_layout_1: 'Bild-Layout 1',
    images_layout_2: 'Bild-Layout 2',
    images_layout_4: 'Bild-Layout 4',
    calculation_layout: 'Kalkulations-Layout 4',
    toc_layout: 'Inhaltsverzeichnis-Layout',
    pages: 'Seiten',
    modules: 'Seiten',
    hide_primary_form: 'Nur Unterformulare anzeigen',
    selectedModules: 'Gewählte Seiten',
    default: 'Als Standard verwenden',
    isDefault: 'Standard',
    use_alternate_logo: 'Alternatives Logo verwenden',
    show_notes: 'Zeige Notizen',
    hide_footer_on_cover_page: 'Fußzeile auf Cover-Seite nicht anzeigen',
    predefined_text: 'Vordefinierter Text',
  },
  pageConfigField: {
    title: 'Seiten',
    description:
      'Damit eine Seite angezeigt wird, aktivierenb Sie die Checkbox im jeweiligen Seitentyp. Sie können die Reihenfolge der Seiten festlegen, indem Sie die Seite mit dem Punkte Icon links per Drag and Drop verschieben. Mit dem Eingebefeld rechts definieren Sie den Seitentitel. Der Seitentitel wird im Inhaltsverzeichnis sowie als Überschrift im Bericht verwendet. ',
    pagesValidation: 'Wähle mindestens eine Seite aus, die im Bericht ausgegeben werden soll.',
  },
  pages: {
    reportLayoutsOverview: {
      title: 'Berichtsvorlagen',
      description: 'Hier finden Sie eine Auflistung von Berichtsvorlagen.',
      toolbar: {
        create: 'Berichtsvorlage erstellen',
      },
    },
  },
  dialogs: {
    reportTemplateCreate: {
      title: 'Berichtsvorlage erstellen',
      selectedModules: {
        title: 'Seiten im Handbuch',
        instruction:
          'Über die <bold>auf-/abwärts gerichteten Pfeile</bold> kann die Reihenfolge der Seiten geändert werden. Über den <bold>Pfeil nach rechts</bold> können die Seiten im Bericht integriert, über den <bold>Pfeil nach links</bold> wieder entfernt werden. Die <bold>Doppelpfeile</bold> nach rechts ermöglichen es, mit einem Klick alle Inhalte in den Bericht übernehmen zu lassen.',
      },
    },
    reportTemplateEdit: {
      title: 'Berichtsvorlage bearbeiten',
    },
    delete: {
      title: 'Berichtsvorlage löschen',
      message: 'Möchten Sie die Berichtsvorlage wirklich löschen?',
    },
  },
  notifications: {
    createReportLayout: {
      success: 'Berichtsvorlage erstellt!',
    },
    editReportLayout: {
      success: 'Berichtsvorlage geändert!',
    },
    deleteReportLayout: {
      success: 'Berichtsvorlage gelöscht!',
    },
  },
  components: {
    reportFooterTooltip: {
      info: {
        title:
          'Hier haben Sie die Möglichkeit die Fußzeile Ihres Berichtes anzupassen. Wenn Sie dynamische Daten automatisch einfügen möchten, nutzen Sie die folgenden Variablen:',
        policy_holder: '<bold>{{policy_holder}}</bold> - der Name des Versicherungsnehmers',
        customer_full_name: '<bold>{{customer_full_name}}</bold> - der Name des Kunden',
        damage_number: '<bold>{{damage_number}}</bold> - die Schadennummer',
        report_type: '<bold>{{report_type}}</bold> - der Berichtstyp',
        report_name: '<bold>{{report_name}}</bold> - der Berichtsname',
        report_version: '<bold>{{report_version}}</bold> - die Berichtsversion',
        current_date: '<bold>{{current_date}}</bold> - das aktuelle Datum',
        current_date_us:
          '<bold>{{current_date_us}}</bold> - das aktuelle Datum in US-Schreibweise (Y-m-d)',
      },
    },
  },
  pageTypes: {
    COVER: 'Cover',
    IMAGES: 'Bilder',
    QUESTIONS: 'Fragen',
    SUMMARY: 'Zusammenfassung',
    CALCULATION: 'Kalkulation',
    DOCUMENTS: 'Dokumente',
    REWORK_DOCUMENTS: 'Nacharbeiten Dokumente',
    ROOM_PLAN: 'Raumplan',
    FREETEXT: 'Freitext',
    TOC: 'Inhaltsverzeichnis',
    PREDEFINED_TEXT: 'Vordefinierter Text',
  },
  coverLayouts: {
    DEFAULT: 'Default',
    DEFAULT_NO_COVER_IMAGE: 'Kein Cover-Bild',
    DEFAULT_INVOICE_CHECK: 'Rechnungs-Prüfung',
    DEFAULT_CLAIMANT_COVER: 'Coverseite mit Anspruchsteller',
    DEFAULT_RISK_COVER: 'Risk-Cover',
    DEFAULT_NO_ID: 'Cover ohne Auftragsnummer',

    DEFAULT_SUBFORM_GROUPING_COVER: 'Coverseite mit Unterformular und Gruppierung - Ohne Indikator',
    RED_SUBFORM_GROUPING_COVER: 'Coverseite mit Unterformular und Gruppierung - Roter Indikator',
    GREEN_SUBFORM_GROUPING_COVER: 'Coverseite mit Unterformular und Gruppierung - Grüner Indikator',
    YELLOW_SUBFORM_GROUPING_COVER:
      'Coverseite mit Unterformular und Gruppierung - Gelber Indikator',

    DEFAULT_SUBFORM_INSURANCE_GROUPING_COVER:
      'Coverseite mit Unterformular, Versicherung und Gruppierung - Ohne Indikator',
    RED_SUBFORM_INSURANCE_GROUPING_COVER:
      'Coverseite mit Unterformular, Versicherung und Gruppierung - Roter Indikator',
    GREEN_SUBFORM_INSURANCE_GROUPING_COVER:
      'Coverseite mit Unterformular, Versicherung und Gruppierung - Grüner Indikator',
    YELLOW_SUBFORM_INSURANCE_GROUPING_COVER:
      'Coverseite mit Unterformular, Versicherung und Gruppierung - Gelber Indikator',
  },
  summaryLayouts: {
    DEFAULT: 'Default',
    SUBFORMS: 'Mit Unterformularen',
  },
  roomplanLayouts: {
    DEFAULT: 'Default',
  },
  questionLayouts: {
    DEFAULT: 'Default',
    COMPACT: 'Kompakt',
  },
  imagesLayout1s: {
    DEFAULT: 'Default',
  },
  imagesLayout2s: {
    DEFAULT: 'Default',
  },
  imagesLayout4s: {
    DEFAULT: 'Default',
  },
  calculationLayouts: {
    DEFAULT: 'Default',
  },
  tocLayouts: {
    DEFAULT: 'Default',
  },
};
